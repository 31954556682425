<template>
  <div class="container">
    <section class="wrapper">
      <div class="placeholder__image"></div>
      <p class="placeholder__text">Сведения, документы и материалы раздела 11 размещаются из основного приложения.</p>
      <a
          :href="$consts.geometaUrl" 
          target="_blank"
          class="button button--primary"
          data-qa="addEntry"
      >Перейти в основное приложение</a>
    </section>
  </div>
</template>
<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.placeholder__image {
  margin-top: calc(50% - 238px);
  margin-bottom: 48px;
  width: 338px;
  height: 238px;
  background-image: url("../assets/svg/section11placeholder.svg");
  background-repeat: no-repeat;
}

.placeholder__text {
  margin-bottom: 24px;
  font-size: 16px;
  max-width: 580px;
  text-align: center;
}
</style>